.pinkBg {
  background-color: #FAFAFA;
}

.adsWrapper {
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 10px;
  
  @media screen and (max-width:430px) {
    padding: 20px 0 0 0;
    padding: 5px !important;
    height: 100%;
  }
}

.adsImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  @media screen and (max-width:430px) {
    height: unset;
    object-fit: unset;
  }
}
.adsDesc {
  font-family: var(--quick-sand-font-family);
  color: white;
  padding: 10px;


  @media screen and (max-width:430px) {
    font-size: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.leftGoogleAds {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px;
  background-color: gray;

  @media screen and (max-width:430px) {
    display: none;
  }
}

.leftGoogleAdsPlaceholder {
  height: 0;

  @media screen and (max-width:430px) {
    height: 100px;
  }
}

.rightGoogleAds {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 200px;
  background-color: gray;

  @media screen and (max-width:430px) {
    display: none;

  }
}
.topGoogleAds {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background-color: gray;

  @media screen and (max-width:430px) {
    right: 0;
    height: 100px;
    z-index: 999;
    display: flex;
  }
}

.bottomGoogleAds {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: gray;

  @media screen and (max-width:430px) {
    height: 100px;
    left: 0;
    z-index: 999;
    display: flex;
  }
}

.rightGoogleAdsPlaceholder {
  height: 0;

  @media screen and (max-width:430px) {
    height: 100px;
  }
}